
import { defineComponent } from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import _ from "lodash";

export default defineComponent({
  name: "SAML",
  props: ["option"],
  data() {
    return {
      companyData: {
        name: "",
      },
    };
  },
  async created() {
    // Old version for Firebase SAML Auth
    // this.initFirebaseSaml();

    const routeName = this.$route.name;
    if (routeName === "sso") {
      await this.initSsoReadySaml();
    } else if (routeName === "sso-callback") {
      this.ssoCallback();
    }
  },
  methods: {
    setCookie(cName: any, cValue: any, expMinutes: any) {
      let date = new Date();
      date.setTime(date.getTime() + expMinutes * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    },
    getCookie(cName: any) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split("; ");
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      });
      return res;
    },
    async initFirebaseSaml() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const provider = new firebase.auth.SAMLAuthProvider(
        "saml." + this.option // !!!!!PUT YOUR PROVIDER ID HERE!!!!!
      );
      if (params["type"] === "saml-authenticate") {
        // Are we logging a user in?
        if (this.getCookie("redirecting") === "true") {
          // Has firebase redirected back here from an SSO page?
          this.setCookie("redirecting", "false", 5); // Reset the redirect cookie in case the user comes back withing 5 minutes (cookie expiration)
          firebase
            .auth()
            .getRedirectResult()
            .then(
              async (result: any) => {
                if (result.credential && result.user) {
                  let credential = result.credential;
                  const user = result.user;
                  let credentialJSON = JSON.stringify(credential.toJSON()); // Conver the auth token into a string so it can be passed back
                  await firebase
                    .database()
                    .ref("loginsso/" + params["id"])
                    .update({
                      credential: credentialJSON,
                      user: JSON.stringify(user),
                    })
                    .then(() => {
                      window.location.href = params["linkingUri"];
                    });
                  // window.location.href =
                  //   params["linkingUri"] + "?credential=" + credentialJSON+'&user='+JSON.stringify(user); // Redirect back to the app
                }
              },
              function (error: any) {
                alert(
                  "Error with authenticating!\n" +
                    "Code:" +
                    error.code +
                    "\n" +
                    error
                );
              }
            );
        } else {
          this.setCookie("redirecting", "true", 5); // Remember that it is a redirect once the user signs in
          firebase.auth().signInWithRedirect(provider); // Go to the sign in page
        }
      } else if (params["linkingUri"].includes("saml-reauthenticate")) {
        // Are we reauthenticating?
        //TODO: find a way to implement
      } else if (params["linkingUri"].includes("saml-link")) {
        // Are we linking an existing user to a SAML SSO?
        //TODO: find a way to implement
      }
    },
    async loadCompanyNameByEntityId(entityId: string) {
      // @todo use new collection of sso to map company
      const allCompany = (
        await firebase.database().ref("company").once("value")
      ).val();
      _.each(allCompany, (company: any) => {
        if (_.has(company, "sso_configuration")) {
          const options = _.get(company, "sso_configuration.options", []);
          let found = false;
          _.each(options, (option: any) => {
            if (found) return;
            if (option.spEntityId === entityId) {
              this.companyData.name = company.name;
              found = true;
            }
          });
        }
      });
    },
    async initSsoReadySaml() {
      const entityId = this.$router.currentRoute.value.params.id as string;

      // Important: URL from mobile app and web app for use the same logic
      // const result = 'https://applink.happily.ai/loginsso/'+res.key;
      // Linking.openURL(`https://accounts.happily.ai/sso/saml/${option.spEntityId}?id=${res.key}&linkingUri=${result}&type=saml-authenticate`);

      const curerntUrl = new URL(window.location.href);
      const queryString = curerntUrl.searchParams.toString();

      this.setCookie("entityId", entityId, 5);
      this.setCookie("queryString", queryString, 5);

      await this.loadCompanyNameByEntityId(entityId);

      const baseUrl = process.env.VUE_APP_API_URL || "";
      const redirectUrl = baseUrl + "/sso/saml/" + entityId + "?" + queryString;
      window.location.href = redirectUrl;
    },
    async ssoCallback() {
      const entityId = this.getCookie("entityId"); // company entity id, sso settings
      const queryString = this.getCookie("queryString");
      if (entityId && queryString) {
        await this.loadCompanyNameByEntityId(entityId);
        const searchParams = new URLSearchParams(queryString);
        const id = searchParams.get("id");
        const linkingUri = searchParams.get("linkingUri");
        if (id && linkingUri) {
          window.location.href = linkingUri;
        }
      } else {
        alert("Something went wrong.");
      }
    },
  },
});
